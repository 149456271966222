/*Font Family Used In Website*/
/*Roboto (Integrated From Google Fonts Library)*/
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap");

body {
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  overflow-x: hidden;
}

body .container {
  width: 100%;
  max-width: 1170px;
}

body img {
  max-width: 100%;
}

section,
header,
footer {
  float: left;
  width: 100%;
}

a {
  transition: all 0.4s ease-in-out;
  -webkit-transition: all 0.4s ease-in-out;
  cursor: pointer !important;
  display: inline-block;
  text-decoration: none !important;
}

.custom-scroll1::-webkit-scrollbar-track {
  background-color: #f5f5f5;
}

.custom-scroll1::-webkit-scrollbar {
  width: 8px;
  background-color: #00b22d;
}

.custom-scroll1::-webkit-scrollbar-thumb {
  background-color: #d8d8d8;
}

.col-white {
  color: white !important;
}
.col-black {
  color: black !important;
}

.bg-white {
  background: white !important;
}

.bg-black {
  background: black !important;
}

.bg-blue1 {
  background: #0077db !important;
}
.bg-blue2 {
  background: #233141 !important;
}

.pad-top-20 {
  padding-top: 20px !important;
}
.pad-top-40 {
  padding-top: 40px !important;
}
.pad-top-60 {
  padding-top: 60px !important;
}
.pad-top-80 {
  padding-top: 80px !important;
}

.pad-bot-20 {
  padding-bottom: 20px !important;
}
.pad-bot-40 {
  padding-bottom: 40px !important;
}
.pad-bot-60 {
  padding-bottom: 60px !important;
}
.pad-bot-80 {
  padding-bottom: 80px !important;
}

.m-b-10 {
  margin-bottom: 10px !important;
}
.m-b-20 {
  margin-bottom: 20px !important;
}
.m-b-30 {
  margin-bottom: 30px !important;
}
.m-b-40 {
  margin-bottom: 40px !important;
}
.m-b-50 {
  margin-bottom: 50px !important;
}

.m-t-10 {
  margin-top: 10px !important;
}
.m-t-20 {
  margin-top: 20px !important;
}
.m-t-30 {
  margin-top: 30px !important;
}
.m-t-40 {
  margin-top: 40px !important;
}
.m-t-50 {
  margin-top: 50px !important;
}

.no-margin {
  margin: 0px !important;
}
.no-pad {
  padding: 0px !important;
}

.modal-dialog {
  top: 50%;
  margin: 0px auto !important;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%) !important;
}

.equal-col {
  align-items: center;
}

.menu-item {
  float: left;
  margin-left: 12px;
  position: relative;
}

.menu-item > a {
  color: #9aadbf;
  padding: 45px 10px;
  text-transform: uppercase;
  letter-spacing: 0.3px;
  font-weight: 400;
  font-size: 14px;
}
.menu-item > a:hover {
  color: #0077db;
}

.menu-active {
  color: #0077db !important;
}

ul.sub-dropdown {
  padding: 0px;
  list-style: none;
  padding: 10px 10px;
  box-shadow: 0 1px 5px rgb(0 0 0 / 15%);
  margin: 0px;
  min-width: 220px;
  position: absolute;
  background: white;
  z-index: 9;
  display: none;
}

.menu-item:hover ul.sub-dropdown {
  display: block;
}

ul.sub-dropdown li a {
  color: #0077db;
  letter-spacing: 0.3px;
  padding: 6px 10px;
  display: block;
}

ul.sub-dropdown {
  top: 99%;
}

ul.sub-dropdown li a:hover {
  color: black;
}

.custom-pad1 {
  padding: 0px 5px;
}

.custom-row1 {
  margin: 0px -5px;
}

.custom-image1 {
  float: left;
  width: 100%;
}

.custom-image1 img {
  width: 100%;
}

.custom-image3 {
  float: left;
  width: 100%;
}

.custom-image3 img {
  width: 100%;
  border: 1px solid white;
}

.custom-image2 {
  float: left;
  width: 100%;
}

.custom-image2 img {
  width: 100%;
}

.block-element {
  float: left;
  width: 100%;
}

.sec-head h3 {
  font-size: 28px;
  letter-spacing: 0.3px;
  font-weight: 500;
}

.form-field1 label {
  display: block;
  font-weight: 500;
  letter-spacing: 0.3px;
  margin: 0px 0px 8px 0px;
  color: grey;
  font-size: 16px;
}

.field-style1 {
  width: 100%;
  height: 45px;
  color: black;
  padding: 10px 10px;
  border: 1px solid #d4d4d4;
  letter-spacing: 0.4px;
  border-radius: 5px;
}

textarea.field-style1 {
  height: 150px;
}
.submit-btn1 {
  border: none;
  background: #0077db;
  color: white;
  font-weight: 500;
  letter-spacing: 0.6px;
  padding: 10px 30px;
}

.bg-silver1 {
  background: #eff3f5 !important;
}

section.contact-banner {
  background: #eff3f5;
  padding: 70px 0px 40px 0px;
}

.col-grey1 {
  color: grey !important;
}

.textual-sec1 {
  float: left;
  width: 100%;
}

.textual-sec1 h3 {
  font-weight: 800;
  font-size: 48px;
  letter-spacing: 2px;
  margin: 0px 0px 5px 0px;
}

.textual-sec1 p {
  font-size: 15px;
  letter-spacing: 0.3px;
  color: grey;
  font-weight: 400;
  margin-bottom: 20px;
}

.line-1 {
  background: silver;
  font-size: 0px;
  width: 70px;
  height: 2px;
}

.line-2 {
  background: #0077db;
  font-size: 0px;
  width: 70px;
  height: 2px;
}

.contact-box {
  display: inline-block;
  width: 48%;
  margin-bottom: 35px;
  position: relative;
  padding-left: 60px;
}

.contact-box svg {
  position: absolute;
  left: 0px;
  top: 12px;
  color: #0077db;
  font-size: 40px;
}

.contact-box h5 {
  font-weight: 500;
  letter-spacing: 0.3px;
  font-size: 16px;
}

.contact-box a {
  font-weight: 500;
  color: #0077db;
  font-size: 15px;
  letter-spacing: 0.3px;
}

.textual-sec2 h3 {
  font-weight: 400;
  font-size: 30px;
  margin-bottom: 18px;
}

.textual-sec2 p {
  font-size: 15px;
  letter-spacing: 0.3px;
  line-height: 24px;
  font-weight: 400;
}

.support-box {
  float: left;
  width: 100%;
  margin-bottom: 30px;
  height: 100%;
}
.support-box h3 {
  font-size: 22px;
  font-weight: 500;
  letter-spacing: 0.3px;
  margin-bottom: 18px;
}
.list-1 {
  padding: 0px 0px 0px 20px;
  margin: 0px;
}
.list-1 li {
  color: grey;
  font-size: 14px;
  letter-spacing: 0.3px;
  margin-bottom: 5px;
}

.list-1 li a {
  font-size: 15px;
  letter-spacing: 0.3px;
}

.custom-border1 {
  border-right: 1px solid #e6e6e6;
}

.custom-border2 {
  border-top: 1px solid #e6e6e6;
}

.textual-sec2 h5 {
  font-size: 16px;
  line-height: 27px;
  font-weight: 400;
  letter-spacing: 0.3px;
}

.about-box {
  float: left;
  width: 100%;
  text-align: center;
}

.about-box img {
  height: 100px;
  margin-bottom: 20px;
}
.about-box h5 {
  font-size: 21px;
  letter-spacing: 0.3px;
  font-weight: 500;
  margin-bottom: 15px;
}

.about-box p {
  font-size: 15px;
  line-height: 25px;
  letter-spacing: 0.2px;
  font-weight: 400;
}

.bg-silver2 {
  background: #e4ebef !important;
}

ul.list-2 {
  padding: 0px;
  list-style: none;
  margin: 0px;
}

ul.list-2 li {
  color: grey;
  font-size: 15px;
  letter-spacing: 0.2px;
  margin-bottom: 10px;
  position: relative;
  padding-left: 28px;
}
ul.list-2 li svg {
  position: absolute;
  left: 0px;
  top: 3px;
  color: #0077db;
}

.textual-sec3 h4 {
  font-weight: 500;
  font-size: 22px;
  letter-spacing: 0.3px;
  margin-bottom: 15px;
}

.textual-sec3 p {
  font-size: 15px;
  letter-spacing: 0.2px;
  line-height: 25px;
  margin-bottom: 15px;
}
.progress-1 {
  height: 10px;
  background: #d7d7d7;
}
.progress-title h5 {
  font-weight: 400;
  letter-spacing: 0.3px;
  font-size: 16px;
}
.progress-title h5 b {
  float: right;
}

.star-onn {
  color: #f8bd06 !important;
}
.star-off {
  color: grey !important;
}
.progress-title2 span {
  margin-right: 10px;
}

.progress-title2 b {
  font-weight: 500;
  margin-right: 10px;
  color: grey;
}

.progress-title2 a {
  font-weight: 500;
}

.review-box {
  float: left;
  width: 100%;
  border-bottom: 1px solid #e6e6e6;
  padding-bottom: 10px;
  margin-bottom: 20px;
}

.review-title {
  float: left;
  width: 100%;
  margin-bottom: 12px;
  position: relative;
  padding-left: 75px;
}

.review-title img {
  position: absolute;
  left: 0px;
  top: 0px;
  width: 60px;
  height: 60px;
  border-radius: 100%;
}

.review-title h5 {
  margin: 0px 0px 0px 0px;
  font-weight: 600;
  font-size: 15px;
  color: #575757;
  letter-spacing: 0.4px;
}

.review-title h6 {
  margin: 0px 0px 0px 0px;
  font-weight: 400;
  font-size: 14px;
  letter-spacing: 0.2px;
  margin: 6px 0px;
}
.review-desc {
  float: left;
  width: 100%;
}

.review-desc p {
  font-size: 15px;
  letter-spacing: 0.2px;
  line-height: 26px;
  color: grey;
  font-weight: 300;
}
.video-1 iframe {
  width: 100%;
}
.sidebar {
  float: left;
  width: 100%;
  background: #e5f3ff;
  padding: 30px 20px;
}

ul.list-3 li a {
  color: #334452;
  font-weight: 400;
  letter-spacing: 0.3px;
}

ul.list-3 li :hover {
  font-weight: bold;
  text-decoration: underline;
}

ul.list-3 li {
  margin-bottom: 8px;
}

ul.list-3 {
  padding: 0px 0px 0px 20px;
  list-style: circle;
  margin: 0px;
}

.news-title h4 {
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 0.4px;
}

.news-block {
  display: flex;
  margin-bottom: 25px;
}

.news-desc {
  width: 70%;
}
.news-desc:nth-child(1) {
  width: 100%;
}
.news-image {
  width: 30%;
}
.news-image img {
  width: 90%;
}

.news-desc h5 {
  margin: 0px 0px 10px 0px;
  font-weight: 500;
  letter-spacing: 0.2px;
}

.news-desc p {
  font-size: 12px;
  letter-spacing: 0.2px;
  line-height: 18px;
  margin-bottom: 0px;
}

.faq-textual {
  float: left;
  width: 100%;
}
.faq-textual h4 {
  font-size: 28px;
  font-weight: 400;
  letter-spacing: 0.3px;
  margin-bottom: 20px;
  line-height: 40px;
}

.faq-textual h5 {
  font-size: 24px;
  font-weight: 400;
  letter-spacing: 0.3px;
  margin-bottom: 20px;
  line-height: 34px;
}
.faq-textual p {
  font-size: 15px;
  letter-spacing: 0.2px;
  line-height: 25px;
  margin-bottom: 20px;
}

.faq-textual li {
  font-size: 15px;
  letter-spacing: 0.2px;
  color: grey;
  line-height: 25px;
  margin-bottom: 12px;
}
.faq-textual ol,
.faq-textual ul {
  padding: 0px 0px 0px 20px;
}

.table-1 {
  float: left;
  width: 100%;
}

.table-1 table {
  width: 100%;
}

.table-1 table th:nth-child(1) {
  width: 15%;
}

.table-1 table th b {
  font-weight: 600;
  text-align: center;
  font-size: 18px;
  letter-spacing: 0.6px;
  color: grey;
}

.table-1 table th {
  font-size: 13px;
  font-weight: 400;
  letter-spacing: 0.2px;
  width: 8%;
  text-align: center;
  border: 1px solid #e4e4e4;
  border-top: none;
  padding: 15px 15px;
}

.col-blue1 {
  color: #0077db !important;
}

.table-1 table tbody tr td {
  text-align: center;
  padding: 21px 10px;
  border: 1px solid #e4e4e4;
}

.table-1 table tbody tr td:nth-child(1),
.table-1 table tbody tr td:nth-child(5) {
  text-align: left;
}

.custom-btn1 {
  background-color: #0077db;
  color: white;
  font-weight: 400;
  letter-spacing: 0.6px;
  padding: 12px 30px;
  background-image: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 0.2) 0,
    rgba(255, 255, 255, 0.01) 100%
  );
  font-size: 15px;
  border: none;
}

.table-1 table tbody tr td span {
  display: block;
  font-size: 13px;
  letter-spacing: 0.2px;
}

.table-1 table tbody tr td b {
  font-size: 32px;
  font-weight: 600;
  display: block;
  line-height: 30px;
  margin-bottom: 10px;
}

.table-1 table tbody tr:nth-child(even) {
  background: #e4ebef;
}

.table-1 table tbody tr td:nth-child(1) b {
  font-size: 20px;
  display: block;
  margin-bottom: 10px;
  line-height: 28px;
}

.table-1 table tbody tr td:nth-child(1) span {
  font-size: 14px;
}
.table-1 table tbody tr td b sub {
  font-size: 14px;
}

.table-1 table tbody tr td:nth-child(5) {
  width: 12%;
}

.sec-head4 h3 {
  font-size: 28px;
  font-weight: 700;
  letter-spacing: 0.6px;
}

.arrows-1 .slick-next:before {
  content: ">";
  font-family: Poppins;
  color: black;
}
.arrows-1 .slick-prev:before {
  content: "<";
  font-family: Poppins;
  color: black;
}

.textual-sec1 h1 {
  font-size: 26px;
  line-height: 38px;
}
.table-1 {
  float: left;
  width: 100%;
  overflow-x: auto;
}

a.textual-btn1 {
  color: grey;
  font-weight: 400;
  font-size: 15px;
  letter-spacing: 0.2px;
  margin-bottom: 5px;
}
a.textual-btn1:hover {
  color: #0077db;
}

.detail-image {
  float: left;
  width: 100%;
}

.detail-text {
  float: left;
  width: 100%;
}
.detail-text h5 {
  font-weight: 400;
  font-size: 15px;
  letter-spacing: 0.8px;
}

.detail-text h3 {
  font-size: 38px;
  font-weight: 500;
  letter-spacing: 0.4px;
  margin-bottom: 24px;
}

.detail-text p {
  font-size: 14px;
  letter-spacing: 0.2px;
  line-height: 24px;
  margin-bottom: 15px;
  max-width: 90%;
}

.custom-btn2 {
  background: #0077db;
  color: white;
  font-weight: 400;
  border: none;
  letter-spacing: 0.6px;
  padding: 12px 25px;
  border-radius: 5px;
}
.custom-btn2:hover {
  background: black;
}

.custom-btn2 svg {
  margin-left: 5px;
}
.detail-image img {
  width: 100%;
}

.detail-text2 {
  background: #e4ebef;
  padding: 30px 40px;
}

.amount-field1 input {
  width: 150px;
}

.amount-field1 b {
  font-weight: 500;
  margin-left: 5px;
  font-size: 18px;
}

.card-field1 {
  width: 300px;
}

.card-field2 {
  width: 120px;
}

.card-field3 {
  width: 100px;
}

.plan-wrapper {
  float: left;
  width: 100%;
  border: 1px solid #0077db;
  text-align: center;
  background: white;
}

.plan-head {
  float: left;
  width: 100%;
  padding: 20px 15px;
}

.plan-head h3 {
  font-weight: 600;
  letter-spacing: 0.3px;
  margin: 0px 0px 12px 0px;
  line-height: 30px;
  font-size: 25px;
}

.plan-head p {
  font-size: 14px;
  color: grey;
  letter-spacing: 0.2px;
  margin-bottom: 6px;
}

.plan-price {
  float: left;
  width: 100%;
  background: #0077db;
  padding: 20px 15px;
}

.plan-price p {
  font-size: 13px;
  color: white;
  margin-bottom: 3px;
}

.plan-price h3 {
  font-size: 35px;
  font-weight: 600;
  color: white;
}

.plan-price h3 sub,
.plan-price h3 sup {
  font-size: 14px;
  font-weight: 400;
}

.custom-btn3 {
  border: 2px solid white;
  color: white;
  font-weight: 500;
  letter-spacing: 1px;
  display: block;
  padding: 10px 0px;
  letter-spacing: 0.8px;
  font-size: 15px;
  cursor: pointer;
}

.plan-price p:nth-last-child(2) {
  margin-bottom: 20px;
}

.plan-desc {
  float: left;
  width: 100%;
  padding: 25px 0px 0px 0px;
}

.plan-desc h4 {
  margin: 0px 0px 15px 0px;
  font-weight: 500;
  letter-spacing: 0.3px;
  color: black;
}

.plan-desc p {
  border-bottom: 1px solid silver;
  padding-bottom: 10px;
  margin-bottom: 0px;
  margin-bottom: 20px;
}

.plan-desc p:nth-last-child(1) {
  margin-bottom: 0px;
}
.plan-desc p span {
  display: block;
  font-size: 13px;
  color: grey;
  letter-spacing: 0.2px;
}

.custom-btn4 {
  background: #dcdcdc;
  padding: 10px 30px;
  color: black;
  border: none;
  letter-spacing: 0.6px;
  border-radius: 5px;
  font-weight: 500;
  font-size: 15px;
}

.custom-btn5 {
  background: white;
  padding: 14px 35px;
  color: #007bff;
  border: none;
  letter-spacing: 0.6px;
  border-radius: 5px;
  font-weight: 500;
  font-size: 16px;
}

.form-extend1 {
  display: none;
}

.trigger-1 {
  background: none;
  border: none;
  color: #0077db;
  font-weight: 500;
  letter-spacing: 0.3px;
  padding: 0px;
}

.detail-text3 {
  float: left;
  width: 100%;
  background: #f7f7f7;
  padding: 30px 40px;
  border-radius: 10px;
}

.detail-text3 h3 {
  font-size: 27px;
  font-weight: 400;
  letter-spacing: 0.3px;
  margin-bottom: 15px;
}
.detail-text3 p {
  font-size: 15px;
  letter-spacing: 0.3px;
  line-height: 24px;
}

.review-cta1 h5 {
  font-size: 22px;
  text-align: center;
}
.review-wrapper2 {
  float: left;
  width: 100%;
  background: #e5f3ff;
  padding: 30px 40px;
}
.review-big .review-desc p {
  font-size: 18px;
  line-height: 32px;
}

.partner-logos img {
  height: 65px;
}

.partner-logos {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.home-reviews .review-desc p {
  font-size: 20px;
  line-height: 32px;
  letter-spacing: 1px;
}
.sec-head6 {
  float: left;
  width: 100%;
}

.sec-head6 h5 {
  font-size: 22px;
  font-weight: 500;
  letter-spacing: 0.8px;
}
.sec-head6 h2 {
  font-size: 65px;
  font-weight: 700;
  line-height: 75px;
  margin-bottom: 30px;
}

.super-fast-box {
  float: left;
  width: 100%;
  text-align: center;
  margin-bottom: 30px;
}

.super-fast-box img {
  height: 80px;
  margin-bottom: 5px;
}

.super-fast-box h4 {
  font-size: 20px;
  letter-spacing: 0.3px;
  font-weight: 600;
}
.super-fast-box p {
  font-weight: 400;
  letter-spacing: 0.4px;
  font-size: 14px;
  line-height: 25px;
}

.detail-text h4 {
  font-weight: 400;
  font-size: 18px;
  letter-spacing: 0.8px;
  line-height: 26px;
}

.custom-border3 {
  border-bottom: 1px dashed silver;
  padding-bottom: 80px;
  margin-bottom: 80px;
}
.features-box {
  float: left;
  width: 100%;
  text-align: center;
  margin-bottom: 60px;
}
.features-box h3 {
  font-size: 60px;
}
.features-box h5 {
  font-weight: 400;
  letter-spacing: 0.3px;
  font-size: 14px;
}
.features-box img {
  height: 58px;
}

.need-box {
  float: left;
  width: 100%;
  text-align: center;
  margin-bottom: 40px;
}
.need-box img {
  margin-bottom: 10px;
}
.need-box h3 {
  font-weight: 500;
}

.need-box p {
  font-size: 15px;
  line-height: 24px;
  letter-spacing: 0.3px;
  padding: 0px 10px;
  margin-bottom: 15px;
  min-height: 120px;
}

.sec-head7 {
  float: left;
  width: 100%;
  text-align: center;
}

.sec-head7 h3 {
  font-size: 35px;
  font-weight: 800;
  letter-spacing: 0.5px;
  margin-bottom: 12px;
}

.sec-head7 p {
  font-size: 17px;
  max-width: 700px;
  margin: auto;
  margin-bottom: 20px;
}

section.banner-sec1 {
  /*background-image: url("/images/banner-bg.jpg");*/
  background-size: cover;
  background-position: top left;
}

.banner-text {
  float: right;
  width: 100%;
  background: #17319b;
  padding: 40px 40px;
  border-radius: 10px;
  max-width: 600px;
  text-align: center;
}

.banner-text h1 {
  font-size: 52px;
  margin: 0px 0px 15px 0px;
  line-height: 61px;
  text-align: right;
  font-weight: 700;
}
.banner-text h5 {
  font-size: 16px;
  font-weight: 400;
  text-align: right;
  margin-bottom: 25px;
}

.custom-btn3:hover {
  background: white;
  color: #0077db;
}
.submit-btn1:hover {
  background: black;
}

.custom-btn5:hover {
  background: #0077db;
  color: white;
}

.custom-btn1:hover {
  background: #17319b;
}

ul.sub-dropdown li a.menu-active {
  color: black !important;
  font-weight: 500 !important;
}

.checkout-content1 {
  float: left;
  width: 100%;
  border-bottom: 1px solid #e6e6e6;
  padding-bottom: 20px;
  margin-bottom: 25px;
}
.checkout-content1 h4 {
  font-weight: 500;
  font-size: 16px;
  letter-spacing: 0.3px;
  border-bottom: 1px solid #e6e6e6;
  padding-bottom: 10px;
  margin-bottom: 20px;
}
.checkout-content1 h6 {
  font-size: 20px;
  font-weight: 500;
  letter-spacing: 0.2px;
}

.table-2 td {
  font-weight: 600;
  letter-spacing: 0.3px;
  padding: 5px 10px;
  font-size: 15px;
}

.checkbox-parent1 label {
  font-size: 15px;
  letter-spacing: 0.2px;
  margin-right: 50px;
  margin-bottom: 10px;
  position: relative;
  padding-left: 25px;
  font-weight: 500;
}
.checkbox-parent1 label input {
  position: absolute;
  left: 0px;
  top: 4px;
  width: 18px;
  height: 15px;
}
/*Media Queries Of Website*/

/*Tablet Devices*/
@media screen and (max-width: 767px) and (min-width: 520px) {
  .menu-item {
    float: left;
    margin-left: 0px;
    width: 100%;
  }
  .menu-item > a {
    color: #9aadbf;
    padding: 8px 0px;
    text-transform: uppercase;
    letter-spacing: 0.3px;
    font-weight: 400;
    font-size: 14px;
  }
  ul.sub-dropdown {
    position: static;
    max-width: 300px;
    margin-bottom: 20px;
    padding: 10px 5px;
  }
  .menu-search {
    float: left;
    margin-left: 0px;
    position: relative;
    margin-top: 0px;
  }
  .menu-search form {
    background: #eee;
    position: absolute;
    right: auto;
    top: 40px;
    display: none;
    left: 0px;
  }
  .support-box {
    height: auto;
  }
  .partner-logos {
    display: block;
    justify-content: space-between;
    align-items: center;
    text-align: center;
  }
  .partner-logos img {
    height: 50px;
    margin: 10px 10px 10px 10px;
  }
  .sec-head6 {
    float: left;
    width: 100%;
    text-align: center;
    margin-bottom: 20px;
  }
  .sec-head6 h2 {
    font-size: 40px;
    font-weight: 700;
    line-height: 48px;
    margin-bottom: 30px;
  }
  .sec-head6 h5 {
    font-size: 20px;
    font-weight: 500;
    letter-spacing: 0.8px;
  }
  .banner-text h1 {
    font-size: 40px;
    line-height: 50px;
    text-align: left;
  }
  .banner-text h5 {
    font-size: 15px;
    font-weight: 400;
    text-align: left;
    margin-bottom: 25px;
    line-height: 24px;
  }
}

/*Mobile Devices*/
@media screen and (max-width: 519px) and (min-width: 320px) {
  .menu-item {
    float: left;
    margin-left: 0px;
    width: 100%;
  }
  .menu-item > a {
    color: #9aadbf;
    padding: 8px 0px;
    text-transform: uppercase;
    letter-spacing: 0.3px;
    font-weight: 400;
    font-size: 14px;
  }
  .menu-search {
    float: left;
    margin-left: 0px;
    position: relative;
    margin-top: 0px;
  }
  .menu-search form {
    background: #eee;
    position: absolute;
    right: auto;
    top: 40px;
    display: none;
    left: 0px;
  }
  ul.sub-dropdown {
    position: static;
    max-width: 300px;
    margin-bottom: 20px;
    padding: 10px 5px;
  }
  .textual-sec1 h3 {
    font-weight: 700;
    font-size: 38px;
    letter-spacing: 1px;
    margin: 0px 0px 5px 0px;
  }

  .contact-box {
    display: inline-block;
    width: 100%;
    margin-bottom: 35px;
    position: relative;
    padding-left: 60px;
  }
  .textual-sec1 {
    float: left;
    width: 100%;
    margin-bottom: 20px;
  }
  .sec-head h1 {
    font-size: 28px;
  }
  .textual-sec2 h3 {
    font-weight: 400;
    font-size: 25px;
    margin-bottom: 18px;
  }
  .support-box h3 {
    font-size: 20px;
    font-weight: 500;
    letter-spacing: 0.3px;
    margin-bottom: 18px;
  }
  .support-box {
    height: auto;
  }
  .faq-textual h4 {
    font-size: 24px;
    font-weight: 400;
    letter-spacing: 0.3px;
    margin-bottom: 20px;
    line-height: 32px;
  }
  .detail-text h3 {
    font-size: 28px;
    font-weight: 500;
    letter-spacing: 0.4px;
    margin-bottom: 20px;
  }
  .detail-image {
    float: left;
    width: 100%;
    margin-bottom: 20px;
  }
  .detail-text2 {
    background: #e4ebef;
    padding: 20px 20px;
  }
  .plan-wrapper {
    margin-bottom: 40px;
  }
  .review-cta1 h5 {
    font-size: 18px;
    text-align: center;
    margin-bottom: 30px;
  }
  .partner-logos {
    display: block;
    justify-content: space-between;
    align-items: center;
    text-align: center;
  }
  .partner-logos img {
    height: 50px;
    margin: 10px 10px 10px 10px;
  }
  .sec-head6 {
    float: left;
    width: 100%;
    text-align: center;
    margin-bottom: 20px;
  }
  .sec-head6 h2 {
    font-size: 40px;
    font-weight: 700;
    line-height: 48px;
    margin-bottom: 30px;
  }
  .sec-head6 h5 {
    font-size: 20px;
    font-weight: 500;
    letter-spacing: 0.8px;
  }
  .features-box h3 {
    font-size: 50px;
  }
  .features-box {
    float: left;
    width: 100%;
    text-align: center;
    margin-bottom: 40px;
  }
  .sec-head7 h3 {
    font-size: 30px;
    font-weight: 700;
    letter-spacing: 0.2px;
    margin-bottom: 12px;
  }
  .need-box h3 {
    font-weight: 500;
    font-size: 22px;
  }
  .banner-text h1 {
    font-size: 40px;
    line-height: 50px;
    text-align: left;
  }
  .banner-text h5 {
    font-size: 15px;
    font-weight: 400;
    text-align: left;
    margin-bottom: 25px;
    line-height: 24px;
  }
  .table-2 {
    margin-bottom: 30px;
    margin-top: 20px;
  }
}

/* Auto complete Check page */
.autocomplete {
  position: relative;
}
.autocomplete-items {
  position: absolute;
  border: 1px solid #d4d4d4;
  border-bottom: none;
  border-top: none;
  z-index: 99;
  /*position the autocomplete items to be the same width as the container:*/
  top: 100%;
  left: 0;
  right: 0;
}

.autocomplete-items div {
  padding: 10px;
  cursor: pointer;
  background-color: #fff;
  border-bottom: 1px solid #d4d4d4;
}

/*when hovering an item:*/
.autocomplete-items div:hover {
  background-color: #e9e9e9;
}

/*when navigating through the items using the arrow keys:*/
.autocomplete-active {
  background-color: DodgerBlue !important;
  color: #ffffff;
}

label {
  cursor: pointer;
}

.error_404 {
  position: relative;
  margin: 100px auto;
  display: table;
  width: 100%;
  max-width: 1200px;
  text-align: center;
}

.error_404 h2 {
  font-size: 120px;
  line-height: 100px;
  margin-bottom: 50px;
}

.error_404 h5 {
  font-size: 22px;
  line-height: 25px;
  margin-bottom: 10px;
}

.sentry-error-embed header {
  background: unset !important;
}

.div-block-308 {
  position: relative;
  height: 100%;
  border-radius: 20px;
  background-image: url("/images/cnb-header.jpg"),
    radial-gradient(
      circle farthest-corner at 100% 10%,
      rgba(0, 0, 0, 0.7),
      hsla(0, 0%, 100%, 0) 26%
    ),
    radial-gradient(
      circle farthest-corner at 10% 70%,
      rgba(0, 0, 0, 0.75) 19%,
      hsla(0, 0%, 100%, 0) 61%
    );
  background-position: 50% 50%, 0px 0px, 0px 0px;
  background-size: cover, auto, auto;
}

.div-block-310 {
  position: relative;
  z-index: 2;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 100%;
  padding: 50px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 15px;
  background-image: radial-gradient(
      circle farthest-corner at 96% 10%,
      rgba(21, 20, 78, 0.49),
      hsla(0, 0%, 100%, 0) 26%
    ),
    radial-gradient(
      circle farthest-corner at 10% 50%,
      rgba(12, 24, 14, 0.75) 19%,
      hsla(0, 0%, 100%, 0) 61%
    );
}
.div-block-309 {
  max-width: none;
  margin-right: 0px;
}
.heading-151 {
  font-family: "Palatino Linotype", "Book Antiqua", Palatino, serif;
  color: #faf9f9;
  font-size: 2.6rem;
  font-weight: 700;
  letter-spacing: 0px;
}

.div-block-261 {
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.text-block-161 {
  font-size: 0.8rem;
}
.paragraph-36 {
  color: #faf9f9;
}

.was-validated .prevent-validation.form-control:valid,
.prevent-validation.form-control.is-valid {
  padding: 0.375rem 0.75rem;
  border-color: #ced4da;
  background-image: none;
}

#nprogress .bar {
  background: black;
}

/* FCC Labels */
.fcc-label-div span {
  font-size: 3.5cqw;
}

.fcc-label-div a {
  font-size: 3.5cqw;
}

.fcc-broadband-header {
  margin-block: 0px;
  font-size: min(9.8cqw, 3.2169rem);
  font-weight: 900;
  line-height: normal;
  word-break: break-word;
}

.fcc-section-header {
  margin-block: 2px;
  font-size: 3.5cqw;
  font-weight: 900;
  letter-spacing: normal;
  line-height: normal;
  word-break: normal;
  text-align: start;
}

.fcc-provider-name {
  margin-block: 0;
  font-weight: 600;
  font-size: 4.5cqw;
  line-height: normal;
  color: #333;
}

.fcc-monthly-price {
  margin-block-start: 2px;
  margin-block-end: 0px;
  font-size: 3.5cqw;
  color: #333;
}

.fcc-base-text {
  margin-block: 0px;
  font-size: 3.5cqw;
  line-height: 1.25;
  letter-spacing: normal;
  font-weight: 400;
}

.fcc-row-right {
  white-space: pre;
  text-align: right;
  font-weight: 900;
  color: #333;
  font-size: 3.5cqw;
  flex-direction: row;
  justify-content: space-between;
  margin-top: auto;
  margin-bottom: auto;
  word-wrap: normal;
  min-width: 40%;
}

.fcc-upin {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  margin-block-start: 8px;
  margin-block-end: 2px;
  color: #333;
  font-size: 3cqw;
  font-weight: 300;
  text-align: left;
  word-break: break-word;
}

.fcc-hr1 {
  width: 100%;
  border-top: 1px solid #333;
  opacity: 100;
  margin: 0px;
}

.fcc-hr2 {
  width: 100%;
  border-top: 2px solid #333;
  opacity: 100;
  margin: 0px;
}

.fcc-hr3 {
  width: 100%;
  border-top: 4px solid #333;
  opacity: 100;
  margin: 0px;
}

.fcc-hr4 {
  width: 100%;
  border-top: 12px solid #333;
  opacity: 100;
  margin: 0px;
}

.fcc-label-div {
  height: 100%;
  box-sizing: border-box;
  margin: 8px;
  padding: 8px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  font-family: "Lucida Grande", "Lucida Sans Unicode", "Lucida Sans",
    "DejaVu Sans", "Verdana", sans-serif;
  container-type: inline-size;
  max-width: 484px;
  border: 4px solid #333;
}

.fcc-product-info-div {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 4px;
  width: 100%;
}

.fcc-list-entry-div {
  display: flex;
  align-items: flex-start;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  padding-bottom: 2px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  word-wrap: normal;
}

.fcc-ul-list-container {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  padding-left: 16px;
  padding-bottom: 8px;
  width: 100%;
}

.fcc-li-list-style {
  list-style-type: none;
  box-sizing: border-box;
  margin-left: 0;
  margin-block: 0;
  padding-inline-start: 0px;
  width: 100%;
  font-size: 1rem;
  font-weight: 300;
}

.fcc-force-right-and-bold {
  width: 100%;
  text-align: right;
  margin-right: 0px;
  font-weight: 900;
}

.fcc-full-width-div {
  width: 100%;
  font-size: 3.5cqw;
}

.fcc-labels-container {
  display: grid;
  column-gap: 26px;
  grid-template-columns: unset;
  grid-auto-flow: column;
  grid-auto-columns: 370px;
  overflow-x: visible;
  overflow-y: hidden;
  scroll-snap-type: x mandatory;
}

.fcc-labels-alignment {
  scroll-snap-align: center;
}
